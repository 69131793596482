import i18n from "i18next";

export const GET_BANK_URL = '/PaymentMethod/user/getPublicBankList'

export const CREATE_PAYOUT_REQUEST_URL = '/PaymentDirectTransfer/user/createPayoutRequest'
export const GET_PAYOUT_REQUEST_URL = '/PaymentDirectTransfer/user/getPayoutRequestByCode'
export const GET_STATUS_PAYMENT = '/PaymentDirectTransfer/user/getDetailPayoutRequestByCode'

export const DATE_FORMAT = "YYYYMMDDHHmmSS"
export const DEPOSIT_PENDING="New"


export const replaceNumberToAmount = (value) => value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0

export const PATH = {
    HOME: "/",
    PAYMENT: "/payment",
    DETAIL_PAYMENT:"/payment/:paymentCode",
    ORDER: "/order/:paymentCode",
    QR_CODE: "/qrcode/:paymentCode/:typeTransaction"
}

export const paymentMethodIcon = {
    zalopayPaymentUrl: {
      img: "/assets/icons/zalo.svg",
      label: i18n.t("zalo_pay"),
      limit: "20,000 - 1,000,000 VND",
      fee: "3%",
      feeAmount: 3,
      limitAmount: { min: 20000, max: 1000000 }
    },
    bankPaymentUrl: {
      img: "/assets/icons/bank_transfer.svg",
      label: i18n.t("bank_transfer"),
      limit: "50,000 - 50,000,000 VND",
      fee: "3%",
      feeAmount: 3,
      limitAmount: { min: 50000, max: 50000000 }
    },
    // cardPaymentUrl: {
    //   img: "/assets/icons/card.svg",
    //   label: i18n.t("card"),
    //   limit: "20,000 - 1,000,000 VND",
    //   fee: "30%",
    //   feeAmount: 30,
    //   limitAmount: { min: 20000, max: 1000000 }
    // },
    momoPaymentUrl: {
      img: "/assets/icons/momo.svg",
      label: i18n.t("momo"),
      limit: "20,000 - 5,000,000 VND",
      fee: "3%",
      feeAmount: 3,
      limitAmount: { min: 20000, max: 5000000 }
    },
    viettelpayPaymentUrl: {
      img: "/assets/icons/viettel.svg",
      label: i18n.t("viettel_pay"),
      limit: "20,000 - 10,000,000 VND",
      fee: "3%",
      feeAmount: 3,
      limitAmount: { min: 20000, max: 10000000 }
    },
    usdtPaymentUrl: {
      img: "/assets/icons/usdt.svg",
      label: i18n.t("usdt_payment"),
      limit: "20,000 - 5,000,000 USDT",
      fee: "3%",
      feeAmount: 3,
      limitAmount: { min: 20000, max: 5000000 }
    },
  };
