import React, { useState, useEffect } from "react";
import { Button, Result, Typography } from "antd";
import styles from '../styles/timer.module.css';
import moment from "moment";
import { DATE_FORMAT } from "../common/common";

const { Text } = Typography;

const CountdownTimer = ({ targetDate, onExpired }) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const [timerId, setTimerId] = useState(0);

    useEffect(() => {
        const now = moment();
        const targetMoment = moment(targetDate, DATE_FORMAT);
        const secondsLeft = targetMoment.diff(now, "seconds");
            setTimeLeft(secondsLeft);

            const timer = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            setTimerId(timer);

            return () => {
                clearInterval(timer);
            };

    }, [targetDate]);

    useEffect(() => {
        if (timeLeft < 0) {
            onExpired()
            clearInterval(timerId);
        }
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <div className={styles["wrap-time"]}>
            <div className={`${styles.timer} me-2`}>{minutes ? String(minutes).padStart(2, "0") : "00"}</div>
            <span>:</span>
            <div className={`${styles.timer} ms-2`}>{seconds ? String(seconds).padStart(2, "0") : "00"}</div>

        </div>
    );
};

export default CountdownTimer;
