import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import styles from "../styles/header.module.css";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useLocation } from "react-router-dom";
import { PATH } from "../common/common";

const MyHeader = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const handleChange = (value) => {
    i18n.changeLanguage(value);
  };
  return (
    <div className={styles.header}>
      <div className={`${styles.content} d-flex-between`}>
        {pathname !== PATH.HOME && (
          <Button
            onClick={() => window.history.back()}
            size="small"
            type="link"
            shape="circle"
            icon={<LeftOutlined />}
          ></Button>
        )}
        <div className="logo d-block">
          <img
            src="/Logo.png"
            alt="AnonyPay | Secure, Fast, Anony payment gateway"
          />
        </div>

        <div>
          <Select
            defaultValue={i18n.language}
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "vi", label: "Tiếng Việt" },
              { value: "en", label: "English" },
              { value: "zh", label: "中国人" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MyHeader;
