// apiService.js
import { notification } from 'antd';
import axios from 'axios';
import { CREATE_PAYOUT_REQUEST_URL, GET_BANK_URL, GET_PAYOUT_REQUEST_URL, GET_STATUS_PAYMENT } from '../common/common';
import i18n from '../i18n';

const apiService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 3000,
    headers: {
        'Content-Type': 'application/json',
    }
});
apiService.defaults.headers['Content-Type'] = 'application/json';

apiService.interceptors.response.use(
    response => response,
    error => {
        console.error('API error:', error);
        return Promise.reject(error);
    }
);
apiService.interceptors.response.use(
    response => {
        return response?.data.data;
    },
    error => {
        const errorMessage = error.response?.data?.error
        const messageExists = i18n.exists(`ERROR_MESSAGE.${errorMessage}`)
        const errorTranslate = messageExists ? i18n.t(`ERROR_MESSAGE.${errorMessage}`) : i18n.t(`ERROR_MESSAGE.DEFAULT`)
        messageExists && notification.error({
            message: messageExists
        })
        return Promise.reject(error);
    }
);
export const getBanks = () => {
    return apiService.post(GET_BANK_URL, {
    })
}

export const createPayOutRequest = (body) => {
    return apiService.post(CREATE_PAYOUT_REQUEST_URL, body)
}

export const getStatusPayment = (body)=>{
    return apiService.post(GET_STATUS_PAYMENT, body)
}

export const getPayOutRequest = (body) => {
    return apiService.post(GET_PAYOUT_REQUEST_URL, body)
}