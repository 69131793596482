import React, { useEffect, useState } from "react";
import styles from "../styles/detaiPayment.module.css";
import { Alert, Button, Col, Divider, notification, QRCode, Result, Row, Space, Spin } from "antd";
import { useTranslation } from "react-i18next";
import CountdownTimer from "../components/Timer";
import { CopyOutlined, DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { DEPOSIT_PENDING, PATH, paymentMethodIcon, replaceNumberToAmount } from "../common/common";
import { useNavigate, useParams } from "react-router-dom";
import { getPayOutRequest, getStatusPayment } from "../service/ApiService";
import PaymentMethod from "../components/PaymentMethod";

export default function DetailPayment() {
  const { t } = useTranslation();
  let { paymentCode } = useParams();
  const navigate = useNavigate();
  const [isDetail, setIsDetail] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [actualSendAmount, setActualSendAmout] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [typeTransaction, setTypeTransaction] = useState("zalopayPaymentUrl");
  const [intervalId, setIntervalId] = useState(null); // Trạng thái để lưu ID của interval


  const handleCalSendAmount = (feeAmount) => {
    const { paymentAmount, requestFeeAmount, receiveFeeAmount } = detailPayment;
    setFeeAmount(paymentAmount * (feeAmount / 100));
    const total = paymentAmount - (paymentAmount * (feeAmount / 100)) - requestFeeAmount - receiveFeeAmount;
    setActualSendAmout(total);
  };

  const shortenTransactionId = (id) => {
    const visibleChars = 4;
    const start = id.substring(0, visibleChars);
    const end = id.substring(id.length - visibleChars);
    return `${start}****${end}`;
  };
  const currentHost = window.location.host;
  const [link, setLink] = React.useState(
    `http://${currentHost}${PATH.ORDER.replace(":paymentCode", paymentCode)}`
  );
  const [loading, setLoading] = useState(false);
  const [detailPayment, setDetailPayment] = useState(false);
  const [status, setStatus] = useState(DEPOSIT_PENDING);

  useEffect(() => {
    setLoading(true);
    getPayOutRequest({
      paymentCode
    })
      .then((response) => {
        setDetailPayment(response);
        const { paymentAmount, requestFeeAmount, receiveFeeAmount } = response;
        const initFee = paymentMethodIcon["zalopayPaymentUrl"].feeAmount;
        setFeeAmount((paymentAmount * (initFee / 100)));
        const total = paymentAmount - (paymentAmount * (initFee / 100)) - requestFeeAmount;
        setActualSendAmout(total);
        setLoading(false);
      })
      .catch((err) => {
        setDetailPayment("NOT_FOUND")
        console.log(err);
        setLoading(false);
      });
    handleGetStatus()
    const interval = setInterval(() => {
      getStatusPayment({ paymentCode })
        .then((response) => {
          if (response.paymentStatus !== DEPOSIT_PENDING) {
            setStatus(response.paymentStatus);
            clearInterval(interval);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 7000); // Gọi fetchData mỗi 7 giây

    // Clear interval khi component bị unmount
    return () => clearInterval(interval);
  }, [paymentCode]);

  const handleGetStatus = () => {
    getStatusPayment({ paymentCode })
      .then((response) => {
        if (response.paymentStatus !== DEPOSIT_PENDING) {
          setStatus(response.paymentStatus);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const handleShowDetail = () => {
    setIsDetail(!isDetail);
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById("myqrcode").querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  if (detailPayment === "NOT_FOUND")
    return (
      <Result
        status="warning"
        title={t("warning_payment_detail")}
        extra={
          <Button onClick={() => navigate(PATH.HOME)} type="primary" key="console">
            {t("back_home")}
          </Button>
        }
      />
    )

  if (status !== DEPOSIT_PENDING)
    return (
      <Result
        status="success"
        title={t("transaction_success")}
      />
    )



  return (
    <div className={styles["wrap-detail-payment"]}>
      <div className="box">
        <div>

          <div className={styles["detail-payment"]}>
            <div className="title-content">
              {t("order_information")}
            </div>
            <div className="d-flex-between">
              <div className="amount">{shortenTransactionId(paymentCode)}</div>
              <Button onClick={handleShowDetail} type="text">
                {t("see_detail")}
              </Button>
            </div>

            {isDetail && (
              <div>
                <Divider />
                <p className="d-flex-between">
                  <span className={styles.label}>{t("requested_amount")} </span>
                  <span className={styles.value}>
                    {replaceNumberToAmount(detailPayment?.paymentAmount) + " VND"}
                  </span>
                </p>
                <p className="d-flex-between">
                  <span className={styles.label}> {t("transaction_fee")} </span>
                  <span className={styles.value}>
                    {replaceNumberToAmount(feeAmount) + " VND"}
                  </span>
                </p>
                <p className="d-flex-between">
                  <span className={styles.label}>{t("order_fee")} </span>
                  <span className={styles.value}>
                    {replaceNumberToAmount(detailPayment?.requestFeeAmount) + " VND"}
                  </span>
                </p>
                <p className="d-flex-between">
                  <span className={styles.label}>
                    {t("actual_amount_received")}{" "}
                  </span>
                  <span className={styles["payment-id"]}>
                    {replaceNumberToAmount(actualSendAmount) + " VND"}
                  </span>
                </p>
                <div>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="title mb-2">{t("scan_qr_payment")}</div>
            <div id="myqrcode" className="d-flex-center">
              <QRCode size={250} value={link || "-"} />
            </div>
            <div className="d-flex-center">
              <Button
                icon={<DownloadOutlined />}
                type="link"
                onClick={downloadQRCode}
              >
                {t("download")}
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(link)
                  notification.success({ message: t("copy_success") })
                }}
                icon={<CopyOutlined />} type="link">
                {t("copy")}
              </Button>
            </div>
            <PaymentMethod
              isOpenPath={false}
              detailPayment={detailPayment}
              paymentMethodIcon={paymentMethodIcon}
              typeTransaction={typeTransaction}
              setTypeTransaction={setTypeTransaction}
              handleCalSendAmount={handleCalSendAmount}
            />
            <Alert
              className={styles.warning}
              message={<>
                <div>{t("warning_message_payment")}</div>
                <a className="ms" href="https://t.me/AnonyPaymentTransaction">https://t.me/AnonyPaymentTransaction</a>
              </>}
              type="warning"
            />
          </div>
        </div>
        <Spin spinning={loading} fullscreen />
      </div>
    </div>
  );
}
