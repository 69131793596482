import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPayOutRequest } from '../service/ApiService';

export default function QrCode() {
    const [detailPayment, setDetailPayment] = useState(false);
    const [loading, setLoading] = useState(false);
    let { paymentCode, typeTransaction } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true)
        getPayOutRequest({
            paymentCode
        })
            .then((response) => {
                setDetailPayment(response);
                setLoading(false);
            })
            .catch((err) => {
                setDetailPayment("NOT_FOUND")
                console.log(err);
                setLoading(false);
            });
    }, [])

    const downloadQRCode = () => {
        const link = document.createElement('a');
        link.href = detailPayment[typeTransaction];
        link.download = "Qr-code";

        // Append the anchor to the document body
        document.body.appendChild(link);

        // Programmatically click the anchor to trigger the download
        link.click();

        // Remove the anchor from the document body
        document.body.removeChild(link);
    };
    return (
        <div>
            <div className="box">
                <Divider />
                <div className="invoice-payment-qr-code">
                    <h3>{t("invoice_name")}</h3>
                    <p>{t('scan_pay')}</p>
                    <div className="qr-code-container">
                        <img id={"myqrcode"} src={detailPayment[typeTransaction]} alt="QR Code for Invoice Payment" />
                    </div>
                    <div className="d-flex-center">
                        <Button
                            icon={<DownloadOutlined />}
                            type="link"
                            onClick={downloadQRCode}
                        >
                            {t("download")}
                        </Button>
                    </div>
                </div>
            </div>
            <Spin spinning={loading} fullscreen />
        </div>
    )
}
