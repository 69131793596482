import React, { useEffect, useState } from "react";
import styles from "../styles/detaiPayment.module.css";
import { Alert, Button, Col, Divider, Result, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CountdownTimer from "../components/Timer";
import { DEPOSIT_PENDING, PATH, paymentMethodIcon, replaceNumberToAmount } from "../common/common";
import { getPayOutRequest, getStatusPayment } from "../service/ApiService";
import PaymentMethod from "../components/PaymentMethod"; // Import PaymentMethod component


export default function DetailPayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { paymentCode } = useParams();
  const [isDetail, setIsDetail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [detailPayment, setDetailPayment] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [actualSendAmount, setActualSendAmout] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [typeTransaction, setTypeTransaction] = useState("zalopayPaymentUrl");
  const [status, setStatus] = useState(DEPOSIT_PENDING);
  const [intervalId, setIntervalId] = useState(null); // Trạng thái để lưu ID của interval



  const shortenTransactionId = (id) => {
    const visibleChars = 4;
    const start = id.substring(0, visibleChars);
    const end = id.substring(id.length - visibleChars);
    return `${start}****${end}`;
  };

  const handleCalSendAmount = (feeAmount) => {
    const { paymentAmount, sendFeeAmount } = detailPayment;
    setFeeAmount(paymentAmount * (feeAmount / 100));
    const total = paymentAmount + (paymentAmount * (feeAmount / 100)) + sendFeeAmount;
    setActualSendAmout(total);
  };

  useEffect(() => {
    setLoading(true);
    getPayOutRequest({ paymentCode })
      .then((response) => {
        setDetailPayment(response);
        const { paymentAmount, sendFeeAmount } = response;
        const initFee = paymentMethodIcon["zalopayPaymentUrl"].feeAmount;
        setFeeAmount(paymentAmount * (initFee / 100));
        const total = paymentAmount + paymentAmount * (initFee / 100) + sendFeeAmount;
        setActualSendAmout(total);
        setLoading(false);
      })
      .catch((err) => {
        setDetailPayment("NOT_FOUND")
        console.log(err);
        setLoading(false);
      });
    handleGetStatus()

    //
    const interval = setInterval(() => {
      getStatusPayment({ paymentCode })
        .then((response) => {
          if (response.paymentStatus !== DEPOSIT_PENDING) {
            setStatus(response.paymentStatus);
            clearInterval(interval);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 7000); // Gọi fetchData mỗi 7 giây

    // Clear interval khi component bị unmount
    return () => clearInterval(interval);
  }, [paymentCode]);

  const handleShowDetail = () => {
    setIsDetail(!isDetail);
  };

  const handleGetStatus = () => {
    getStatusPayment({ paymentCode })
      .then((response) => {
        if (response.paymentStatus !== DEPOSIT_PENDING) {
          setStatus(response.paymentStatus);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (detailPayment === "NOT_FOUND")
    return (
      <Result
        status="warning"
        title={t("warning_payment_detail")}
        extra={
          <Button onClick={() => navigate(PATH.HOME)} type="primary" key="console">
            {t("back_home")}
          </Button>
        }
      />
    )

  if (status !== DEPOSIT_PENDING)
    return (
      <Result
        status="success"
        title={t("transaction_success")}
      />
    )



  return (
    <div>
      <div className="box">
        {/* {isExpired ? (
          <Result
            status="warning"
            title={t("warning_payment_detail")}
            extra={
              <Button onClick={() => navigate(PATH.HOME)} type="primary" key="console">
                {t("back_home")}
              </Button>
            }
          />
        ) : ( */}
        <div>
          {/* <div className={`${styles.time} d-flex-between`}>
              <span className={styles["time-text"]}>{t("trade_ex_late")}</span>
              <CountdownTimer onExpired={() => setIsExpired(true)} targetDate={detailPayment?.paymentExpiredAt} />
            </div> */}
          <div className={styles["detail-payment"]}>
            <div className="title-content">
              {t("order_information")}
            </div>
            <div className="d-flex-between">
              <div className="amount">{shortenTransactionId(paymentCode)}</div>
              <Button onClick={handleShowDetail} type="text">
                {t("see_detail")}
              </Button>
            </div>

            {isDetail && (
              <div>
                <Divider />
                <p className="d-flex-between">
                  <span className={styles.label}>{t("requested_amount")} </span>
                  <span className={styles.value}>
                    {replaceNumberToAmount(detailPayment?.paymentAmount) + " VND"}
                  </span>
                </p>
                <p className="d-flex-between">
                  <span className={styles.label}>{t("transfer_fee")} </span>
                  <span className={styles.value}>
                    {replaceNumberToAmount(detailPayment?.sendFeeAmount) + " VND"}
                  </span>
                </p>
                <p className="d-flex-between">
                  <span className={styles.label}>{t("transaction_fee")} </span>
                  <span className={styles.value}>
                    {replaceNumberToAmount(feeAmount) + " VND"}
                  </span>
                </p>
                <p className="d-flex-between">
                  <span className={styles.label}>{t("amount_to_pay")} </span>
                  <span className={styles["payment-id"]}>
                    {replaceNumberToAmount(actualSendAmount) + " VND"}
                  </span>
                </p>
              </div>
            )}
          </div>
          <PaymentMethod
            detailPayment={detailPayment}
            paymentMethodIcon={paymentMethodIcon}
            typeTransaction={typeTransaction}
            setTypeTransaction={setTypeTransaction}
            handleCalSendAmount={handleCalSendAmount}
          />
          <Alert
            className={styles.warning}
            message={<>
              <div className="mt-2">{t('infor_payment_tele')}</div>
              <a className="ms" href="https://t.me/AnonyPaymentTransaction">https://t.me/AnonyPaymentTransaction</a>
            </>}
            type="warning"
          />
        </div>
        {/* )} */}
        <Spin spinning={loading} fullscreen />
      </div>
    </div>
  );
}
